<template>
    <uw-content title="客户联系人" padding="10px">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.dialogCreate.Open()">新建联系人</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>
            
            <!-- 检索 -->
            <template slot="search">
                <el-input @input="$lodash.debounce(TableSearch, 500)"  v-model="search.name" clearable  size="mini" placeholder="联系人名称"></el-input>
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 表数据 -->
            <template slot="data">
                <!-- 表内容 -->
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    @sort-change="TableSort"
                    height="100%">

                    <!-- 序号 -->
                    <vxe-column width="60" type="seq" title="序号"></vxe-column>

                    <!-- 联系人 -->
                    <vxe-column min-width="120" field="name" title="联系人" sortable>
                        <template #default="{ row }">
                            <el-link @click="$refs.dialogDrawer.Open(row.id)" :type="row.sex == '女' ? 'danger' : 'primary'" icon="el-icon-user">
                                {{ row.name }}
                            </el-link>
                        </template>
                    </vxe-column>

                    <!-- 联系人 -->
                    <vxe-column min-width="80" field="name" title="性别" sortable>
                        <template #default="{ row }">
                            <el-link :underline="false" :type="row.sex == '女' ? 'danger' : 'primary'"   v-if="row.sex">{{ row.sex }}</el-link>
                            <el-link :underline="false" type="info" v-else>{{ row.sex || '未记录' }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 客户单位 -->
                    <vxe-column min-width="250" field="name" title="客户单位" sortable>
                        <template #default="{ row }">
                            <el-link @click="$refs.customerDrawer.Open(row.customer.id)" :type="row.sex == '女' ? 'danger' : 'primary'" icon="el-icon-school">{{ row.customer?.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 工作电话 -->
                    <vxe-column min-width="160" field="phone" title="手机">
                        <template #default="{ row }">
                            <el-link :underline="false" icon="el-icon-mobile-phone" v-if="row.phone || row.phone_t">{{ row.phone || row.phone_t }}</el-link>
                            <el-link :underline="false" icon="el-icon-mobile-phone" v-if="!row.phone && !row.phone_t" type="info">未记录</el-link>
                        </template>
                    </vxe-column>

                    <!-- 关键决策者 -->
                    <vxe-column min-width="90" field="policymakers" title="关键决策者">
                        <template #default="{ row }">
                            <el-link :underline="false" icon="el-icon-circle-check"     type="warning"   v-if="row.policymakers == '是'">{{ row.policymakers }}</el-link>
                            <el-link :underline="false" icon="el-icon-warning-outline"  type="info"      v-else >{{ row.policymakers || 未记录 }}</el-link>
                        </template>
                    </vxe-column>
                    
                    <!-- 联系地址 -->
                    <vxe-column min-width="90" field="country" title="联系地址">
                        <template #default="{ row }">
                            {{ ((row.country || '') + (row.country_province || '') + (row.country_province_city || '') + (row.address || '')) || '未登记' }}
                        </template>
                    </vxe-column>

                    <!-- 操作 -->
                    <vxe-column width="100"  title="操作" align="center" fixed="right">
                        <template #default="{ row }">
                            <el-link @click.native.stop="$refs.dialogUpdate.Open(row.id)" type="primary" class="w-margin-r-10">修改</el-link>
                            <el-link @click.native.stop="TableEventDelete(row.id)" type="danger">移除</el-link>
                        </template>
                    </vxe-column>

                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>
            
        </uw-table>

        <!-- 组件 -->
        <crm-contacts-create ref="dialogCreate" @onChange="TableSearch()" />
        <crm-contacts-update ref="dialogUpdate" @onChange="TableSearch()" />
        <crm-contacts-drawer ref="dialogDrawer" @onChange="TableSearch()" />
        <crm-customer-drawer ref="customerDrawer" @onChange="TableSearch()" />
        
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            // 加载状态
            loading: false,

            // 数据
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                name: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {
        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/crm/contacts/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['user_admin', 'customer']
                }
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 移除数据
        |--------------------------------------------------
        |
        */

        TableEventDelete(id) {
            this.$http.post('/9api/crm/contacts/delete', { id: id }).then(rps => {
                this.TableSearch()
            })
        }
    }
}
</script>